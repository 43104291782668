<script setup lang="ts">
// Components
import ContractsCardsList from '@/app-modules/configuration/components/contracts/cards/List.vue';

// Stores
const contractService = contractStore();

// Lifecycle
onMounted(async () => {
  await contractService.loadContracts();
});
</script>
<template>
  <div class="grid gap-2">
    <contracts-cards-list />
  </div>
</template>
