<script setup lang="ts">
// Components
import ContractsContract from '@/app-modules/configuration/components/contracts/Contract.vue';

// Stores
const contractService = contractStore();
</script>
<template>
  <ui-card :title="$t('contract.current_contracts')">
    <template #header>
      <nuxt-link to="/contract/create">
        <ui-button left-icon="Plus">{{ $t('contract.add_contract') }}</ui-button>
      </nuxt-link>
    </template>
    <p class="text-sm text-gray-600 mb-2">{{ $t('contract.electricity_contracts') }}</p>
    <div class="flex flex-col gap-2">
      <contracts-contract
        v-for="contract in contractService.getElectricityContracts"
        :key="contract.id"
        :contract="contract"
        @edit-contract="$router.push(`/contract/edit/${contract.id}`)"
      />
      <p v-if="contractService.getElectricityContracts.length === 0" class="text-sm text-gray-500">
        {{ $t('contract.no_electricity_contracts') }}
      </p>
    </div>
    <p class="text-sm text-gray-600 mb-2 mt-4">{{ $t('contract.gas_contracts') }}</p>
    <div class="flex flex-col gap-2">
      <contracts-contract
        v-for="contract in contractService.getGasContracts"
        :key="contract.id"
        :contract="contract"
        @edit-contract="$router.push(`/contract/edit/${contract.id}`)"
      />
      <p v-if="contractService.getGasContracts.length === 0" class="text-sm text-gray-500">{{ $t('contract.no_gas_contracts') }}</p>
    </div>
  </ui-card>
</template>
