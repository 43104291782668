<script setup lang="ts">
import dayjs from 'dayjs';
import type { Contract } from '~/types/contract';

// Props
const emits = defineEmits<{
  (e: 'editContract', contract: Contract['id']): void;
}>();
const props = defineProps<{
  contract: Contract;
}>();

// Methods
const editContract = () => {
  emits('editContract', props.contract.id);
};
</script>
<template>
  <div class="flex flex-row bg-gray-50 border border-gray-200 rounded p-2">
    <div class="flex flex-row justify-center items-center">
      <div
        class="border rounded p-2"
        :class="contract.energy_type === 'Electricity' ? 'bg-green-100 border-green-200' : 'bg-orange-100 border-orange-200'"
      >
        <ui-icon
          :name="contract.energy_type === 'Electricity' ? 'Zap' : 'Flame'"
          :class="contract.energy_type === 'Electricity' ? 'text-green-500' : 'text-orange-500'"
          class="w-5 h-5"
        />
      </div>

      <!-- Energy type and contract type -->
      <div class="ml-2">
        <p class="text-md font-semibold text-gray-700">{{ $t(`global.energy_type.${contract.energy_type}`) }}</p>
        <p class="text-xs text-gray-400">
          {{ $t('contract.contract_type') }} :
          {{ contract.tariffs.length <= 1 ? $t('contract.tariff.base') : $t('global.tariff.peak_and_off_peack') }}
        </p>
      </div>

      <!-- Dates -->
      <div class="ml-[100px] flex flex-col justify-center">
        <p class="text-xs mb-1 text-gray-400">
          <span class="font-semibold text-gray-700">{{ $t('global.start_date') }} :</span>
          {{ dayjs(contract.start).format('DD/MM/YYYY') }}
        </p>
        <p class="text-xs text-gray-400">
          <span class="font-semibold text-gray-700">{{ $t('global.end_date') }} :</span>
          {{ dayjs(contract.end).format('DD/MM/YYYY') }}
        </p>
      </div>

      <!-- Contract power -->
      <div class="ml-[100px] flex flex-col justify-center">
        <p class="text-xs mb-1 text-gray-400">
          <span class="font-semibold text-gray-700">{{ $t('global.power') }} :</span>
          {{ contract.contract_power }} kW
        </p>
        <p class="text-xs text-gray-400">
          <span class="font-semibold text-gray-700">{{ $t('global.emission_factor') }} :</span>
          {{ contract.emission_factor }} gCO2/kWh
        </p>
      </div>
    </div>
    <div class="ml-auto">
      <div
        class="h-full flex flex-col justify-center bg-gray-200 hover:bg-gray-300 border border-gray-300 text-gray-700 cursor-pointer rounded-md p-2 px-4"
        @click="editContract"
      >
        <ui-icon name="Pencil" class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>
